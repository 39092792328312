import { createAction, props } from '@ngrx/store';
import { BlastSettingsDto, FinancialToggleSettingsDto, PaymentProcessorSettingsDto } from 'src/app/services/api.service';

export const Init = createAction('[TenantSettings] Init');

export const LoadRequest = createAction('[TenantSettings] LoadRequest');
export const LoadSuccess = createAction(
  '[TenantSettings] LoadSuccess',
  props<{ financial: FinancialToggleSettingsDto; blast: BlastSettingsDto; paymentProcessor: PaymentProcessorSettingsDto }>()
);
export const LoadFailure = createAction('[TenantSettings] LoadFailure', props<{ error: string | unknown }>());
