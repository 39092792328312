import { Action, createReducer, on } from '@ngrx/store';
import { AuthStoreActions } from '../auth-store';
import * as TenantSettingsActions from './actions';
import { State, initialState } from './state';

const reducer = createReducer(
  initialState,
  //LOAD
  on(TenantSettingsActions.LoadRequest, (state) => ({ ...state, isLoading: true, error: null })),
  on(
    TenantSettingsActions.LoadSuccess,
    (state, action) =>
      <State>{
        ...state,
        financial: action.financial ? action.financial.toJSON() : initialState.financial,
        blast: action.blast ? action.blast.toJSON() : initialState.blast,
        paymentProcessor: action.paymentProcessor.processor,
        paymentProcessorId: action.paymentProcessor.id,
      }
  ),
  on(TenantSettingsActions.LoadFailure, (state, action) => ({ ...state, isLoading: true, error: action.error })),
  //LOGOUT
  on(AuthStoreActions.Logout, (state) => initialState)
);

export function featureReducer(state: State | undefined, action: Action): State {
  return reducer(state, action);
}
