import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentBase } from 'src/app/core/ComponentBase';

@Component({
  selector: 'app-reflect',
  templateUrl: './reflect.component.html',
  styleUrls: ['./reflect.component.scss'],
})
export class ReflectComponent extends ComponentBase {
  constructor(activatedRoute: ActivatedRoute) {
    super();

    this.__subSink.sink = activatedRoute.queryParamMap.subscribe((params) => {
      const values = {};
      params.keys.forEach((key) => (values[key] = params.get(key)));

      if (window.parent) {
        const message = { isAva: true, isReflect: true, params: values };
        window.parent.postMessage(message, null);
      }
    });
  }
}
