import { IBlastSettingsDto, IFinancialToggleSettingsDto } from 'src/app/services/api.service';

export interface State {
  isLoading?: boolean;
  error?: string | unknown;
  financial: IFinancialToggleSettingsDto;
  blast: IBlastSettingsDto;
  paymentProcessor: 'GRAVITY' | 'EBIZCHARGE' | null;
  paymentProcessorId: string | null;
}

export const initialState: State = {
  isLoading: false,
  error: null,
  financial: {
    isContractsEnabled: false,
    isLedgersEnabled: false,
    isSlidersEnabled: false,
  },
  blast: {
    maxSendAmount: 100,
  },
  paymentProcessor: null,
  paymentProcessorId: null,
};
