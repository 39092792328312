import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { ComponentBase } from 'src/app/core/ComponentBase';
import { RootStoreState } from 'src/app/root-store';
import { TerminalClient, TransactionRequest } from 'src/app/services/api.service';
import { IEBizChargeTokenizeCardResponse } from '../../IEBizChargeTokenizeCardResponse';

@Component({
  selector: 'app-tokenize-card',
  templateUrl: './tokenize-card.component.html',
  styleUrls: ['./tokenize-card.component.scss'],
})
export class TokenizeCardComponent extends ComponentBase {
  private _url: SafeValue;
  public get FormUrl(): SafeValue {
    return this._url;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { request: TransactionRequest; patientId: number; ledgerId: number },
    private _store$: Store<RootStoreState.State>,
    private _dialogRef: MatDialogRef<TokenizeCardComponent>,
    private _ledgerTerminalClient: TerminalClient,
    sanitizer: DomSanitizer
  ) {
    super();

    this.__working();
    this.__subSink.sink = _ledgerTerminalClient
      .terminal_GetLedgerTerminal(data.patientId, data.ledgerId, data.request)
      .pipe(
        take(1),
        tap((_) => this.__doneWorking())
      )
      .subscribe((result) => (this._url = sanitizer.bypassSecurityTrustResourceUrl(result.transactionToken)));

    window.addEventListener('message', this._eventListener.bind(this));
  }

  private _eventListener(e: MessageEvent) {
    const data = e.data;
    if (typeof data == 'object' && 'isAva' in data && 'isReflect' in data) {
      console.log(data);
      this._savePaymentMethodToken(data);
      window.removeEventListener('message', this._eventListener, false);
    }
  }

  private _savePaymentMethodToken(data: Record<string, unknown>): void {
    this._dialogRef.close(data.params as IEBizChargeTokenizeCardResponse);
  }
}
