import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TransactionRequest } from 'src/app/services/api.service';
import { RootStoreState } from '../../root-store';
import { IEBizChargeTokenizeCardResponse } from './IEBizChargeTokenizeCardResponse';
import { TokenizeCardComponent } from './modal/tokenize-card/tokenize-card.component';

@Injectable()
export class EBizChargeService {
  constructor(private _store$: Store<RootStoreState.State>, private _dialog: MatDialog) {}

  public openCardTokenizationDialog(request: TransactionRequest, patientId: number, ledgerId: number): Observable<IEBizChargeTokenizeCardResponse> {
    return this._dialog.open(TokenizeCardComponent, { data: { request, patientId, ledgerId }, minWidth: '850px' }).afterClosed();
  }
}
