import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map, mapTo, switchMap } from 'rxjs/operators';
import { AdminClient } from 'src/app/services/api.service';
import { AuthStoreActions } from '../auth-store';
import { State } from '../root-state';
import * as TenantSettingsStoreActions from './actions';

@Injectable({ providedIn: 'root' })
export class TenantSettingsStoreEffects implements OnInitEffects {
  constructor(private _store$: Store<State>, private _actions$: Actions, private _adminClient: AdminClient) {}

  ngrxOnInitEffects(): Action {
    return TenantSettingsStoreActions.Init();
  }

  loadRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TenantSettingsStoreActions.LoadRequest),
      switchMap(() =>
        combineLatest([
          this._adminClient.admin_GetFinancialToggle(),
          this._adminClient.admin_GetBlastSettings(),
          this._adminClient.admin_PaymentProcessorSettings(),
        ])
      ),
      map(([financial, blast, paymentProcessor]) =>
        TenantSettingsStoreActions.LoadSuccess({
          financial: financial,
          blast: blast,
          paymentProcessor: paymentProcessor,
        })
      )
    )
  );

  loadOnLogin$ = createEffect(() => this._actions$.pipe(ofType(AuthStoreActions.LoginSuccess), mapTo(TenantSettingsStoreActions.LoadRequest())));
}
