import { animate, state, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import * as moment from 'moment';
import { EMPTY, noop, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ComponentBase } from 'src/app/core/ComponentBase';
import { IPerson } from 'src/app/models/AvaContext';
import { EmergePayTransactionResponse } from 'src/app/models/EmergePayTransactionResponse';
import {
  AutoPayClient,
  IPatientLedgerDto,
  LedgerAutoPayDto,
  LedgerClient,
  LedgerTransaction,
  PatientLedgerDto,
  PatientLedgerTransactionDto,
  PostingCodeClient,
  PostingCodeDto,
  PostingCodeTypeEnum,
  SendTransactionReceiptRequestDto,
  TransactionClient,
  UpdateLedgerDescriptionDto,
} from 'src/app/services/api.service';
import { AutoPayDialogComponentResult, CreateAutoPayDialogComponent } from '../modals/create-auto-pay-dialog/create-auto-pay-dialog.component';
import {
  IImportSliderDialogData,
  IImportSliderDialogResult,
  ImportSliderDialogComponent,
} from '../modals/import-slider-dialog/import-slider-dialog.component';
import { LedgerPostDialogComponent, LedgerPostDialogData } from '../modals/ledger-post-dialog/ledger-post-dialog.component';
import { PaymentPlanDialogComponent, PaymentPlanDialogData } from '../modals/payment-plan-dialog/payment-plan-dialog.component';

@Component({
  selector: 'app-ledgers',
  templateUrl: './ledgers.component.html',
  styleUrls: ['./ledgers.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  host: {
    '[class.disabled]': 'this.disabled',
  },
})
export class LedgersComponent extends ComponentBase implements OnInit {
  private _patient: IPerson;
  public get patient(): IPerson {
    return this._patient;
  }
  @Input('patient')
  public set patient(value: IPerson) {
    this._patient = value;
    if (!value.isLead) {
      this.dataSource = new LedgerDataSource();

      if (!value.isChild && value.email) {
        this.recipientOptions.push({ name: value.firstName + ' ' + value.lastName, email: value.email });
      }

      value.patientResponsiblePartyLinks
        .map((x) => x.responsibleParty)
        .filter((rp) => rp.canSendEmail)
        .forEach((rp) => this.recipientOptions.push({ name: rp.firstName + ' ' + rp.lastName, email: rp.email }));
    }
  }

  @Input('disabled') disabled: boolean = false;

  @ViewChild('recipientOptionDialogTemplate', { static: true }) recipientOptionDialogTemplate: TemplateRef<unknown>;

  displayedColumns: string[] = ['ledgerId', 'description', 'dueNow', 'overdue', 'nextDue', 'balance', 'commands'];
  dataSource: LedgerDataSource;
  isExpansionDetailRow = (i: number, row: LedgerDataType): boolean => row.detailRow == true;
  expandedElement: number;
  PostingCodeTypeEnum = PostingCodeTypeEnum;
  postingCodes: PostingCodeDto[];
  recipientOptions: { name: string; email: string }[] = [];

  constructor(
    private _dialog: MatDialog,
    private _snackbar: MatSnackBar,
    private _ledgerClient: LedgerClient,
    private _postingCodeClient: PostingCodeClient,
    private _autoPayClient: AutoPayClient,
    private _transactionClient: TransactionClient
  ) {
    super();
  }

  ngOnInit(): void {
    this._getPostingCodes();
    this._getLedgers();
  }

  private _getPostingCodes() {
    this.__subSink.sink = this._postingCodeClient.postingCode_GetPostingCodes().subscribe((result) => (this.postingCodes = result));
  }

  private _getLedgers() {
    this.__subSink.sink = this._ledgerClient.ledger_GetLedgers(this._patient.id).subscribe((result) => this.dataSource.refresh(result));
  }

  assertItemType(element: PatientLedgerDto): PatientLedgerDto {
    return element;
  }

  createLedger(): void {
    this.__subSink.sink = this._ledgerClient.ledger_CreateLedger(this._patient.id).subscribe((result) => this.dataSource.next(result));
  }

  deleteLedger(ledgerId: number): void {
    this.__subSink.sink = this._ledgerClient.ledger_DeleteLedger(this._patient.id, ledgerId).subscribe(() => this.dataSource.delete(ledgerId));
  }

  postingCodeName(postingCodeId: number): string {
    if (postingCodeId) {
      return this.postingCodes && this.postingCodes.find((x) => x.postingCodeId == postingCodeId).name;
    } else {
      return null;
    }
  }

  openNewPostDialog(ledgerId: number): void {
    const data: LedgerPostDialogData = {
      patientId: this._patient.id,
      ledgerId: ledgerId,
    };
    this.__subSink.sink = this._dialog
      .open<LedgerPostDialogComponent, LedgerPostDialogData, (PatientLedgerTransactionDto & { patientId: number }) | EmergePayTransactionResponse>(
        LedgerPostDialogComponent,
        { data }
      )
      .afterClosed()
      .pipe(
        filter((result) => !!result),
        switchMap((result) =>
          'ledgerId' in result ? this._ledgerClient.ledger_GetLedger(result.patientId, result.ledgerId) : of(<PatientLedgerDto>null)
        )
      )
      .subscribe((ledger) => {
        if (ledger) {
          this.dataSource.update(ledger);
        }
      });
  }

  updateLedgerDescription(patientId: number, ledgerId: number, description: string): void {
    this.__subSink.sink = this._ledgerClient
      .ledger_UpdateLedgerDescription(patientId, ledgerId, new UpdateLedgerDescriptionDto({ description }))
      .subscribe();
  }

  openAutoPayDialog(ledgerId: number): void {
    const data = { patientId: this._patient.id, ledgerId };
    this.__subSink.sink = this._dialog
      .open<CreateAutoPayDialogComponent, { patientId: number; ledgerId: number }, AutoPayDialogComponentResult>(CreateAutoPayDialogComponent, {
        data,
      })
      .afterClosed()
      .pipe(
        filter((result) => !!result),
        map((result) => {
          return result.processor == 'GRAVITY'
            ? new LedgerAutoPayDto({
                accountExpiry: result.data.accountExpiryDate,
                cardHolderAddress: result.data.cardHolderAddress,
                cardHolderName: result.data.billingName,
                ledgerId: ledgerId,
                maskedAccount: result.data.maskedAccount,
                transactionToken: result.data.uniqueTransId,
                zip: result.data.zip,
                ledgerAutoPayId: 0,
              })
            : result.processor == 'EBBIZCHARGE'
            ? new LedgerAutoPayDto({
                accountExpiry: '',
                cardHolderAddress: result.data.billingAddress,
                cardHolderName: result.data.billingName,
                ledgerId: ledgerId,
                maskedAccount: result.data.MaskedCC,
                transactionToken: result.data.PmToken,
                zip: result.data.billingPostalCode,
                ledgerAutoPayId: 0,
              })
            : null;
        }),
        switchMap((result) => this._autoPayClient.autoPay_CreateOrUpdateLedgerAutoPay(this._patient.id, ledgerId, result))
      )
      .subscribe((result) => {
        if (result) {
          this._snackbar.open('Succesfully added Auto Pay', 'OK', { duration: 3000 });
          this.dataSource.update(ledgerId, { ledgerAutoPayId: result.ledgerAutoPayId });
        }
      });
  }

  removeAutoPay(ledger: PatientLedgerDto): void {
    this.__subSink.sink = this._autoPayClient
      .autoPay_DeleteLedgerAutoPay(this._patient.id, ledger.ledgerId, ledger.ledgerAutoPayId)
      .subscribe((result) => {
        if (result) {
          this._snackbar.open('Succesfully removed Auto Pay', 'OK', { duration: 3000 });
          this.dataSource.update(ledger.ledgerId, { ledgerAutoPayId: null });
        }
      });
  }

  voidTransaction(ledger: PatientLedgerDto, transaction: PatientLedgerTransactionDto): void {
    this._transactionClient
      .transaction_VoidLedgerTransaction(this._patient.id, ledger.ledgerId, transaction.ledgerTransactionId)
      .pipe(switchMap(() => this._ledgerClient.ledger_GetLedger(this._patient.id, ledger.ledgerId)))
      .subscribe(
        (result) => {
          this._snackbar.open('Transaction successfully voided/refunded', 'OK', { duration: 4000 });
          this.dataSource.update(result);
        },
        (result: HttpErrorResponse) =>
          this._snackbar.open(`There was a problem while trying to void transaction: ${result.error.message}`, 'OK', { duration: 5000 })
      );
  }

  transactionOrderByFn(a: PatientLedgerTransactionDto, b: PatientLedgerTransactionDto): number {
    return a.transactionDate.getTime() - b.transactionDate.getTime();
  }

  openPaymentPlanDialog(ledgerId: number): void {
    this.__subSink.sink = this._dialog
      .open<PaymentPlanDialogComponent, PaymentPlanDialogData>(PaymentPlanDialogComponent, {
        data: { ledgerId: ledgerId, patientId: this._patient.id },
        width: '800px',
      })
      .afterClosed()
      .pipe(switchMap((result) => (result ? this._ledgerClient.ledger_GetLedger(this.patient.id, ledgerId) : EMPTY)))
      .subscribe((ledger) => {
        if (ledger) {
          this.dataSource.update(ledger);
          this._getLedgers();
        }
      });
  }

  getLocalTime(date: Date): Date {
    return moment.utc(date).toDate();
  }

  openSliderImportDialog(ledgerId: number): void {
    this.__subSink.sink = this._dialog
      .open<ImportSliderDialogComponent, IImportSliderDialogData, IImportSliderDialogResult>(ImportSliderDialogComponent, {
        data: { ledgerId: ledgerId, patientId: this._patient.id },
        width: '800px',
      })
      .afterClosed()
      .pipe(
        tap(() => this.__working()),
        switchMap((result) =>
          result ? this._ledgerClient.ledger_PopulateLedgerFromSlider(result.patientId, result.ledgerId, result.sliderId).pipe(take(1)) : of(false)
        ),
        switchMap((result) => (result ? this._ledgerClient.ledger_GetLedger(this.patient.id, ledgerId).pipe(take(1)) : EMPTY))
      )
      .subscribe(
        (ledger) => {
          if (ledger) {
            this.dataSource.update(ledger);
          }
        },
        noop,
        () => this.__doneWorking()
      );
  }

  openSendTransactionReceiptDialog(ledgerTransaction: LedgerTransaction): void {
    this.__subSink.sink = this._dialog
      .open(this.recipientOptionDialogTemplate)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this._sendTransactionReceipt(ledgerTransaction, result.email, result.name);
        }
      });
  }

  private _sendTransactionReceipt(ledgerTransaction: LedgerTransaction, email: string, name: string = null): void {
    this.__subSink.sink = this._transactionClient
      .transaction_SendTransactionReceipt(
        this._patient.id,
        ledgerTransaction.ledgerId,
        ledgerTransaction.ledgerTransactionId,
        new SendTransactionReceiptRequestDto({ name, email })
      )
      .subscribe();
  }
}

type LedgerDataType = IPatientLedgerDto & { detailRow?: boolean };
class LedgerDataSource extends DataSource<LedgerDataType> {
  private _dataStream: Subject<LedgerDataType[]> = new Subject();
  private _rows: LedgerDataType[] = [];

  constructor() {
    super();
  }

  update(ledger: IPatientLedgerDto): void;
  update(ledgerId: number, updates: Partial<IPatientLedgerDto>): void;
  update(item: IPatientLedgerDto | number, updates: Partial<IPatientLedgerDto> = null): void {
    if (typeof item === 'number') {
      const ind = this._rows.findIndex((x) => x.ledgerId == item);
      Object.assign(this._rows[ind], updates);
      Object.assign(this._rows[ind + 1], updates);
    } else {
      const ind = this._rows.findIndex((x) => x.ledgerId == item.ledgerId);
      if (ind != -1) {
        this._rows[ind] = item;
        this._rows[ind + 1] = { ...item, detailRow: true };
        this._dataStream.next(this._rows);
      }
    }
  }

  refresh(items: PatientLedgerDto[]): void {
    this._rows = [];
    items.forEach((x) => this._rows.push(x, { detailRow: true, ...x }));
    this._dataStream.next(this._rows);
  }

  next(newLedgers: PatientLedgerDto[]): void;
  next(newLedgers: PatientLedgerDto): void;
  next(item: PatientLedgerDto | PatientLedgerDto[]): void {
    let arr: PatientLedgerDto[];
    if (Array.isArray(item)) {
      arr = item;
    } else {
      arr = [item];
    }
    arr.forEach((x) => this._rows.push(x, { detailRow: true, ...x }));
    this._dataStream.next(this._rows);
  }

  delete(ledgerId: number) {
    const ind = this._rows.findIndex((x) => x.ledgerId == ledgerId);
    if (ind != -1) {
      this._rows.splice(ind, 2);
      this._dataStream.next(this._rows);
    }
  }

  connect(): Observable<LedgerDataType[]> {
    return this._dataStream.asObservable();
  }

  disconnect() {
    //NYI
  }
}
