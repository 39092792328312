import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule, MatDialogModule } from '@angular/material';
import { EBizChargeService } from './ebizcharge.service';
import { TokenizeCardComponent } from './modal/tokenize-card/tokenize-card.component';

@NgModule({
  declarations: [TokenizeCardComponent],
  imports: [CommonModule, MatButtonModule, MatDialogModule],
  providers: [EBizChargeService],
  entryComponents: [TokenizeCardComponent],
})
export class EBizChargeModule {}
