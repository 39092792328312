import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { LocationStoreSelectors, RootStoreState, TenantSettingsStoreSelectors } from 'src/app/root-store';
import {
  IPatientLedgerTransactionDto,
  PatientLedgerNoteDto,
  PatientLedgerTransactionDto,
  PostingCodeClient,
  PostingCodeDto,
  PostingCodeTypeEnum,
  SendTransactionReceiptRequestDto,
  TerminalClient,
  TransactionClient,
  TransactionRequest,
  TransactionTypeEnum,
} from 'src/app/services/api.service';
import { EmergePayService } from 'src/app/services/emergepay.service';
import { PatientService } from 'src/app/services/patient.service';
import { EBizChargeService } from 'src/app/shared/ebizcharge/ebizcharge.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-ledger-post-dialog',
  templateUrl: './ledger-post-dialog.component.html',
  styleUrls: ['./ledger-post-dialog.component.scss'],
})
export class LedgerPostDialogComponent implements OnInit {
  private _subSink = new SubSink();
  public billingAddress: string;
  public billingZip: string;
  TransactionTypeEnum = TransactionTypeEnum;
  _postingCodes: PostingCodeDto[];
  private get _patientId(): number {
    return this._data.patientId;
  }
  private get _ledgerId(): number {
    return this._data.ledgerId;
  }
  recipientOptions: { name: string; email: string }[] = [];
  private _paymentProcessor$ = this._store.select(TenantSettingsStoreSelectors.selectPaymentProcessor);

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: LedgerPostDialogData,
    private _dialogRef: MatDialogRef<LedgerPostDialogComponent>,
    private _patientService: PatientService,
    private _store: Store<RootStoreState.State>,
    private _emergePayService: EmergePayService,
    private _snackbar: MatSnackBar,
    private _postingCodeClient: PostingCodeClient,
    private _transactionClient: TransactionClient,
    private _terminalClient: TerminalClient,
    private _eBizChargeService: EBizChargeService
  ) {}

  ngOnInit(): void {
    this._subSink.sink = this._patientService.getPatientDetails(this._data.patientId, false).subscribe((result) => {
      if (!result.patient.isLead) {
        this.billingAddress = result.patient.address;
        this.billingZip = result.patient.zip;

        if (!result.patient.isChild && result.patient.email) {
          this.recipientOptions.push({ name: result.patient.firstName + ' ' + result.patient.lastName, email: result.patient.email });
        }

        result.patient.patientResponsiblePartyLinks
          .map((x) => x.responsibleParty)
          .filter((rp) => rp.canSendEmail)
          .forEach((rp) => this.recipientOptions.push({ name: rp.firstName + ' ' + rp.lastName, email: rp.email }));
      }
    });
    this._subSink.sink = this._postingCodeClient.postingCode_GetPostingCodes().subscribe((postingCodes) => (this._postingCodes = postingCodes));
  }

  ngOnDestory(): void {
    this._subSink.unsubscribe();
  }

  private _defaultTransaction(): Observable<PatientLedgerTransactionDto> {
    return this._store.select(LocationStoreSelectors.getSelectedLocationId).pipe(
      map(
        (locationId) =>
          new PatientLedgerTransactionDto({
            transactionDate: new Date(),
            ledgerId: this._ledgerId,
            locationId: locationId,
            amount: 0,
            balance: 0,
            isHiddenTransaction: false,
            isLocked: false,
            ledgerTransactionId: 0,
            postedDate: new Date(),
            postingCodeId: 0,
            postingCodeType: PostingCodeTypeEnum.Note,
          })
      ),
      take(1)
    );
  }

  getPostingCodeIdByName(value: string): number {
    const code = this._postingCodes && this._postingCodes.find((x) => x.name.toUpperCase() === value.toUpperCase());
    return code && code.postingCodeId;
  }

  submit(formValues: LedgerFormValues): void {
    let result: Observable<PatientLedgerTransactionDto>;
    const transactionFn = (model: IPatientLedgerTransactionDto) =>
      this._defaultTransaction().pipe(
        switchMap((transaction) => {
          transaction = Object.assign(transaction, model);
          return this._transactionClient.transaction_PostTransaction(this._patientId, this._ledgerId, transaction);
        })
      );

    switch (formValues.__uniqueId) {
      case 'CHARGE': {
        const model = <IPatientLedgerTransactionDto>{
          amount: formValues.amount,
          postingCodeId: formValues.postingCodeId,
          postingCodeType: PostingCodeTypeEnum.Charge,
          referenceMemo: formValues.referenceMemo,
        };
        result = transactionFn(model);
        break;
      }

      case 'CHARGE_ADJUSTMENT': {
        const model = <IPatientLedgerTransactionDto>{
          amount: formValues.amount,
          postingCodeId: formValues.postingCodeId,
          postingCodeType: PostingCodeTypeEnum.ChargeAdjustment,
          referenceMemo: formValues.referenceMemo,
        };
        result = transactionFn(model);
        break;
      }

      case 'PAYMENT': {
        const model = <IPatientLedgerTransactionDto>{
          amount: formValues.amount,
          postingCodeId: formValues.postingCodeId,
          postingCodeType: PostingCodeTypeEnum.Payment,
          referenceMemo: formValues.referenceMemo,
        };
        result = transactionFn(model).pipe(
          tap((res) => {
            if (formValues.sendConfirmEmail) {
              this._transactionClient
                .transaction_SendTransactionReceipt(
                  this._patientId,
                  this._ledgerId,
                  res.ledgerTransactionId,
                  new SendTransactionReceiptRequestDto(formValues.receiptPerson)
                )
                .subscribe();
            }
          })
        );
        break;
      }

      case 'TERMINAL_PAYMENT': {
        const request = new TransactionRequest({
          billingAddress: formValues.billingAddress,
          billingName: formValues.billingName,
          billingPostalCode: formValues.billingZip,
          amount: formValues.amount,
          transactionType: formValues.paymentMethod,
        });
        result = this._paymentProcessor$.pipe(
          switchMap((processor) => {
            switch (processor) {
              case 'GRAVITY':
                return this._terminalClient.terminal_GetLedgerTerminal(this._patientId, this._ledgerId, request).pipe(
                  switchMap((terminal) => this._emergePayService.open(terminal.transactionToken)),
                  map(() => <PatientLedgerTransactionDto>{ ledgerId: this._ledgerId }),
                  tap(() => this._snackbar.open('Gravity transaction may not immediately appear until full acknowledgment', 'OK', { duration: 2000 }))
                );
              case 'EBIZCHARGE':
                return this._eBizChargeService.openCardTokenizationDialog(request, this._patientId, this._ledgerId).pipe(
                  map((_) => <PatientLedgerTransactionDto>{ ledgerId: this._ledgerId }),
                  tap(() =>
                    this._snackbar.open('EBizCharge transaction may not immediately appear until full acknowledgment', 'OK', { duration: 2000 })
                  )
                );
            }
          })
        );
        break;
      }

      case 'PAYMENT_ADJUSTMENT': {
        const model = new PatientLedgerTransactionDto(<IPatientLedgerTransactionDto>{
          amount: formValues.amount,
          postingCodeId: formValues.postingCodeId,
          postingCodeType: PostingCodeTypeEnum.PaymentAdjustment,
          referenceMemo: formValues.referenceMemo,
        });
        result = transactionFn(model);
        break;
      }

      case 'NOTE': {
        if (formValues.referenceMemo == null) {
          return;
        }

        const model = new PatientLedgerNoteDto({
          referenceMemo: formValues.referenceMemo,
          ledgerId: this._ledgerId,
          ledgerTransactionId: 0,
          transactionDate: undefined,
        });
        result = this._transactionClient.transaction_PostLedgerNote(this._patientId, this._ledgerId, model);
        break;
      }
    }

    if (result == null) {
      throw Error('Error when trying to post transaction: Transaction type not recognized');
    }

    this._subSink.sink = result.pipe(take(1)).subscribe((result) => {
      this._dialogRef.close({ ...result, patientId: this._patientId });
    });
  }
}

export interface LedgerPostDialogData {
  patientId: number;
  ledgerId: number;
}

type LedgerFormValues = { referenceMemo?: string; postingCodeId: number } & (
  | LedgerChargeData
  | LedgerChargeAdjustment
  | LedgerPayment
  | LedgerTerminalPayment
  | LedgerPaymentAdjustment
  | LedgerNote
);

interface LedgerChargeData {
  __uniqueId: 'CHARGE';
  amount: number;
}

interface LedgerChargeAdjustment {
  __uniqueId: 'CHARGE_ADJUSTMENT';
  amount: number;
}

interface LedgerPayment {
  __uniqueId: 'PAYMENT';
  amount: number;
  sendConfirmEmail: boolean;
  receiptPerson?: { email: string; name: string };
}

interface LedgerTerminalPayment {
  __uniqueId: 'TERMINAL_PAYMENT';
  billingName: string;
  billingAddress: string;
  billingZip: string;
  amount: number;
  paymentMethod: TransactionTypeEnum;
  sendConfirmEmail: boolean;
  receiptEmailAddress?: string;
}

interface LedgerPaymentAdjustment {
  __uniqueId: 'PAYMENT_ADJUSTMENT';
  amount: number;
}

interface LedgerNote {
  __uniqueId: 'NOTE';
}
